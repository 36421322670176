import React from 'react';
import App from 'next/app';
import * as Sentry from '@sentry/node';

import printJoinUsMessage from '@nubank/www-latam-commons/utils/asciiart/printJoinUsMessage';
import { compose } from '@nubank/www-latam-commons/utils/functionalUtils';
import { persistMGMParameters } from '@nubank/www-latam-commons/utils/mgmUtils';
import { queryStringToParameters } from '@nubank/www-latam-commons/utils/urlUtils';
import addWebpSupportClass from '@nubank/www-latam-commons/utils/browserFeatures/webp/addWebpSupportClass';
import { IGNORE_ERRORS, IGNORE_URLS } from '@nubank/www-latam-commons/utils/sentry';
import ImgGlobalStyle from '@nubank/www-latam-commons/styles/ImgGlobalStyles';
import { persistMarketingURL } from '@nubank/www-latam-commons/utils/marketingUtils';

import { SiteContextProvider } from '../components/SiteContext/SiteContext';

import '@splidejs/react-splide/dist/css/splide-core.min.css';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  ignoreErrors: IGNORE_ERRORS,
  ignoreUrls: IGNORE_URLS,
});

export default class CustomApp extends App {
  constructor(props, ctx) {
    super(props, ctx);
    printJoinUsMessage('https://boards.greenhouse.io/nubank?gh_src=beda5e891');
  }

  static async getInitialProps({ Component, ctx }) {
    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {};

    return {
      pageProps,
    };
  }

  async componentDidMount() {
    persistMarketingURL(document.location);

    compose(
      persistMGMParameters(),
      queryStringToParameters,
    )(document.location.search);

    addWebpSupportClass(document.querySelector('body'));
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <ImgGlobalStyle />
        <SiteContextProvider>
          <Component {...pageProps} />
        </SiteContextProvider>
      </>
    );
  }
}
